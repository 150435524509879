<template>
  <admin>
    <metatag title="Create Infobip Account"></metatag>
    <page-header :back="{ to: { name: 'infobip-account.index' }, text: trans('Infobip Accounts') }">
      <template v-slot:title><span v-text="trans('Create Infobip Account')"></span></template>
    </page-header>

    <div class="col-lg-9 col-12">
      <DetailsForm />
    </div>
  </admin>
</template>

<script>
import DetailsForm from "./partials/forms/Details.vue";

export default {
  components: {
    DetailsForm,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
